import { render, staticRenderFns } from "./EmployeeFeedbacks.vue?vue&type=template&id=00d05233&scoped=true"
import script from "./EmployeeFeedbacks.vue?vue&type=script&lang=js"
export * from "./EmployeeFeedbacks.vue?vue&type=script&lang=js"
import style0 from "./EmployeeFeedbacks.vue?vue&type=style&index=0&id=00d05233&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00d05233",
  null
  
)

export default component.exports