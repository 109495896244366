<template>
  <div >
    <section
      class="main no-flex"
      style="margin-top: 0px;"
    >

      <EmployeeListing
        @click="handleUser"
        @show-other-component="showOtherComponent = true"
        @show-the-component="showOtherComponent = false"
      />
      <EmployeeDetailsListing
        v-if="!showOtherComponent"
        :user="user"
      />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import EmployeeDetailsListing from '@/components/feedbacks/EmployeeFeedbackDetailsListing.vue'
import EmployeeListing from '@/components/feedbacks/EmployeeFeedbackListing.vue'


export default {
  name: 'Home',
  components: {
    EmployeeDetailsListing,
    EmployeeListing,
  },
  data() {
    return {
      user: '',
      showOtherComponent: true,
    }
  },
  computed: {
    ...mapGetters(['getIsLoading']),

  },
  methods: {
    materialId(id) {
      this.materialIdToShow = id
    },
    handleUser(id) {
      this.user = id;
    },
  },
}
</script>

<style scoped lang="scss">
.main{
  padding: 60px 15px 15px 110px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px){
  .main{
    padding: 60px 10px 15px 90px;
  }
}

@media screen and (max-width: 1200px){
  .main{
    padding: 60px 10px 15px 10px;
  }
}

@media screen and (max-width: 1041px) {
  .main {
    padding: 90px 0.75rem 15px 0.75rem;
  }
}
</style>
