<template>
  <div
    style="margin-top: 0px"

    @click="removeLists"
  >
    <!-- v-if="showRightTable" -->

    <div style="display: flex;align-items: baseline;">
      <!-- v-if="!showRightTable" -->
      <button
        v-if="!(client =='FjollaNila' && getLoggedInUser.role == 'HR')"
        v-b-modal.add-employee-review
        class="button"
        :style="getLanguage == 'en' ? 'border-radius: 6px; width:220px; margin-bottom: 10px' : 'border-radius: 6px; width:250px; margin-bottom: 10px'"
      >
        <b-icon-plus />
        {{ $t("AddReview") }}
      </button>
      <!-- <button
        v-else
        class="addEmployeeButton button"
        :style="getLanguage == 'en' ? 'border-radius: 6px; width:220px; margin-bottom: 10px' : 'border-radius: 6px; width:250px; margin-bottom: 10px'"
        @click="showRightTable = false"
      >
        <b-icon-plus />
        {{ $t("Goback") }}
      </button> -->
      <!-- :disabled="showRightTable" -->
      <div
        class="year"
      >
        <!-- :disabled="showRightTable" -->
        <button
          style="background: #FF274F; border: none;"
          @click="decrementYear"
        >
          <b-icon-arrow-left color="white" />
        </button>
        <h5>{{ year }}</h5>
        <!-- :disabled="showRightTable" -->
        <button
          style="background: #FF274F; border: none;"
          @click="incrementYear"
        >
          <b-icon-arrow-right color="white" />
        </button>
      </div>
      <!-- v-if="showRightTable" -->
      <p
        style="font-size: 30px; font-weight: bold; ;"
      >{{ $t(monthToString(monthhh)) }}</p>
    </div>
    <div class="table-container">
      <!-- Default table -->
      <!-- v-show="!showRightTable" -->
      <div
        class="table-left"
      >
        <p style="font-size: 32px; font-weight: bold; border-bottom: 1px solid lightgray;margin: 22px 0px;">
          {{ user.firstName + ' ' + user.lastName }}
        </p>
        <PdfDocument
          :method-name="'loadFeedback'"
          :getter-name="'getFeedback'"
          :pdf-name="'Feedback'"
          :is-pagination="false"
          :header-name="header"
          :object-value="objectPDF"
          :start-date="year"
          :person="`${user.firstName} ${user.lastName}`"
          :pdf-title="$t('Feedback')"
          style="margin-top: 1.3%;"
        />
        <div>
          <div
            style="width: 100%; padding-top: 16px"
            class="scroll"
          >
            <table
              class="team_table"
              style="width: 100%; box-shadow: none; font-size: 12px"
            >
              <thead>
                <tr style="text-align: center;">
                  <th>
                    {{ $t("Month") }}
                  </th>
                  <th>
                    {{ $t("Rating") }}
                  </th>
                  <th>
                    {{ $t("Feedback") }}
                  </th>
                  <th>
                    {{ $t("ExecutorName") }}
                  </th>
                  <th>
                    {{ $t("Actions") }}
                  </th>
                  <th
                    v-if="getLanguage == 'en'"
                  >
                    {{ user.firstName }} {{ user.lastName }}'s {{ $t("WorkLog") }}
                  </th>
                  <th
                    v-else
                  >
                    {{ $t('WorkLog') }} {{ user.firstName }} {{ user.lastName }}
                  </th>


                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(userr, index) in filtered"
                  :key="index"
                  style="text-align: center;cursor: pointer;"
                  @click="matProps(userr)"
                >
                  <td>
                    {{ $t(monthToString(userr.month)) }}
                  </td>

                  <td style="padding: 9px;display: flex; justify-content: center;">
                    <b-form>
                      <b-form-rating
                        v-model="userr.rangingType"
                        variant="warning"
                        class="mb-2"
                        readonly
                      />
                    </b-form>
                  </td>
                  <td style="width: 25%;">
                    {{ userr.notes }}
                  </td>
                  <td>
                    {{ userr.executorName }}
                  </td>
                  <td>
                    <p
                      v-b-modal.edit-employee-review
                      class="p-0 m-0 ml-50"
                      @click="edit(userr.feedbackId), editObj(userr)"
                    >
                      <b-icon-pencil />
                    </p>
                  </td>
                  <td
                    v-b-toggle.sidebar-right
                    @click="toggleRightTable(userr)"
                  >
                    <!-- <button @click="toggleRightTable(userr)">
                      Show Right Table
                    </button> -->
                    <img
                      src="@/assets//images/next.png"
                      alt=""
                      style="width: 30px;"
                    >
                  </td>

                </tr>
                <div style="margin: 10px;">
                  <b-button
                    v-if="selectedId != null"
                    class="viewAllBtn"
                    @click="showAll"
                  >
                    {{ $t("ViewAll") }}
                  </b-button>
                </div>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <AddEmployeeReview
        :user="user"
        :used-months="existingArray"
        :year="year"
        @submit="addReview"
      />
      <div
        v-if="isSidebarOpen"
        class="backdrop"
      />
      <EditEmployeeReview
        :user="user"
        :feedback-id="feedbackId"
        :obj="editObject"
        @edit="editFeedback"
      />
      <EmployeeTrackAndTraceFedback
        @onCancelToggle="onCancelToggle"
      />
    <!-- @pushFormUser="AddEditVacation" -->
    </div>
  </div></template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { client } from '@/domainConfig'
import PdfDocument from '@/components/PdfDocument/DownloadPDF.vue'
import AddEmployeeReview from './modals/AddFeedbackModal.vue'
import EditEmployeeReview from './modals/EditFeedbackModal.vue'
import EmployeeTrackAndTraceFedback from './EmployeeTrackAndTraceInFeedback.vue'


export default {
  components: {
    // LeaveRequestEmployeeModal,
    AddEmployeeReview,
    EditEmployeeReview,
    EmployeeTrackAndTraceFedback,
    PdfDocument,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: Object,
  },
  data() {
    return {
      isSidebarOpen: false,
      client: client.clientName,
      userObj: {},
      showOtherComponent: false,
      showRightTable: false,
      isactive: true,
      searchUser: '',
      noSearch: false,
      existingArray: [],
      monthhh: null,
      editObject: null,
      year: new Date().getFullYear(),
      sortedR: false,
      sortedRI: false,
      selectedId: null,
      feedbackId: null,
      rate: 0,
      editUser: {
        userId: '',
        firstName: '',
        lastName: '',
        salutation: '',
        gender: '',
        city: '',
        dateOfBirth: '',
        phoneNumber: '',
        postalCode: '',
        doorNumber: '',
        street: '',
        country: '',
        email: '',
        role: '',
        resetPassword: false,
      },
      deactivateItemId: {},
      activateItemId: {},
      totalItems: '',
      page: 1,
      pageSize: 15,
      status: true,
      arrowCounter: 0,
      enterPressed: false,
      employeeId: null,
      header: [
        {
          month: 'Month', rangingType: 'Ratings', notes: 'Feedback', executorName: 'Executor',
        },
      ],
      objectPDF: {
        userId: this.userId,
        year: this.year,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getUsers',
      'getInactiveUsers',
      'getFilteredActiveUsers',
      'getFilteredInactiveUsers',
      'getTotalItemsForUsers',
      'getTotalItemsForInActiveUsers',
      'getFeedback',
      'getLanguage',
      'getTrackAndTraceByEmployee',
      'getLoggedInUser',
    ]),
    filtered() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.getFeedback.sort((a, b) => { return b.month - a.month })
    },
  },
  watch: {
    year(value) {
      // if (!this.showRightTable) {
      this.loadFeedback({ userId: this.user.userId, year: value })
      // } else {
      // }
    },
    getFeedback(value) {
      const array = value.map((item) => { return item.month })
      this.existingArray = array
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions([
      'getUsersPagination',
      'getInactiveUsersPagination',
      'filterActiveUsersByName',
      'resetFilteredActiveUsers',
      'filterInactiveUsersByName',
      'resetFilteredInactiveUsers',
      'changeLoadingtoTrue',
      'loadVacationRequests',
      'assignEmployeeToStoree',
      'leaveRequest',
      'remainingDaysOfEmployee',
      'filterActiveUsersByName',
      'add_feedback',
      'loadFeedback',
      'edit_feedback',
      'loadEmployeeFeedbackPagination',
      'loadTrackAndTraceByEmployee',
    ]),
    onCancelToggle() {
      this.isSidebarOpen = false
    },
    toggleRightTable(value) {
      this.isSidebarOpen = true
      this.showRightTable = !this.showRightTable;
      console.log('value', value)
      this.monthhh = value.month
      this.loadTrackAndTraceByEmployee({
        processingUserId: this.user.userId,
        year: this.year,
        month: value.month,
        pageNumber: 1,
        pageSize: 12,
      })
    },
    test(value) {
      // console.log(value)
      if (value === 'Bad') {
        this.rate = 1;
      } else if (value === 'Good') {
        this.rate = 2; // or 3, based on your logic
      } else if (value === 'Better') {
        this.rate = 4;
      } else if (value === 'Best') {
        this.rate = 5;
      }
      return ''; // You can return any value you want here
    },
    edit(value) {
      this.feedbackId = value
    },
    editObj(value) {
      this.editObject = value
    },
    matProps(users) {
      // this.$emit('click', users);
      this.classNameCh = users.userId;
      // this.$emit('show-the-component');
    },
    saveEmployeeId(id) {
      this.employeeId = id
    },
    async assignEmployeeToFiliale(obj) {
      await this.assignEmployeeToStoree({
        object: obj,
        successCallback: () => {
          this.getUsersPagination({
            status: this.status,
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
        },
      });
    },
    addReview(value) {
      this.add_feedback({
        object: value,
        successCallback: () => {
          this.loadEmployeeFeedbackPagination({
            firstName: null,
            lastName: null,
            pageNumber: 1,
            pageSize: 15,
          })
          this.loadFeedback({ userId: this.user.userId, year: this.year })
        },
      })
    },
    editFeedback(value) {
      this.edit_feedback({
        feedbackId: this.feedbackId,
        object: value,
        successCallback: () => {
          this.loadEmployeeFeedbackPagination({
            firstName: null,
            lastName: null,
            pageNumber: 1,
            pageSize: 15,
          })
          this.loadFeedback({ userId: this.user.userId, year: moment().year() })
        },
      })
    },
    showAll() {
      // set the selected id to null to show all rows
      this.selectedId = null;
      this.$emit('show-other-component');
    },
    toggleBtn(truee) {
      this.sync = truee;
    },
    convertUTCDateToLoacalDate(date) {
      const local = moment.utc(date).local().format('DD-MMM-YYYY h:mm A')
      return local
    },
    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY');
    },
    saveUser(value) {
      this.userObj = value
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredActiveUsers();
      this.resetFilteredInactiveUsers();
    },
    incrementYear() {
      this.year++;
    },
    decrementYear() {
      this.year--;
    },
    monthToString(value) {
      if (value == 1) {
        return 'Janar'
      } if (value == 2) {
        return 'February'
      } if (value == 3) {
        return 'March'
      } if (value == 4) {
        return 'April'
      } if (value == 5) {
        return 'May'
      } if (value == 6) {
        return 'June'
      } if (value == 7) {
        return 'July'
      } if (value == 8) {
        return 'August'
      } if (value == 9) {
        return 'September'
      } if (value == 10) {
        return 'Octomber'
      } if (value == 11) {
        return 'November'
      } if (value == 12) {
        return 'December'
      }
      return ''
    },
  },
};
</script>

<style scoped lang="scss">

.team_table td {
  padding: 16px 9px !important;
}
.table-container {
  display: flex;
  overflow: hidden;
}

.table-left,
.table-right {
  flex: 1;
  transition: transform 0.3s ease-in-out;
}

.table-right {
  transform: translateX(0); /* Start from fully visible */
}

/* Slide out to the left */
.v-enter-active,
.v-leave-active {
  transition: transform 0.3s ease-in-out;
}

.v-enter-to,
.v-leave-to {
  transform: translateX(-100%); /* Slide out to the left */
}
.selected-table {
  box-shadow: none; /* Add shadow to the selected table */
  /* or you can use border to highlight the selected table */
  /* border: 2px solid #007bff; */
}






.button {
  width: 195px;
}

.viewAllBtn{
  background: #FF274F;
}
.addEmployeeButton {
  width: 210px;
}

@media screen and (max-width: 1600px) {
  .scroll {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1110px) {
  .button {
    margin-right: 10px;
    font-size: 12px;
    height: 36px;
    width: 150px;
  }
}

.dropdrop::-webkit-scrollbar {
  display: none;
}
span{
  color: black;
}
p{
  color: black;
}
table td{
  color: black;
}
.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;;
  z-index: 3;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.black {
  position: relative;
}

.black input {
  border: none;
  box-shadow: none;;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

@media screen and (max-width: 763px) {
  .con {
    flex-wrap: wrap;
  }

  .active-inactive-con {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .button {
    height: auto;
  }
}

.is-active {
  background-color: #dedede;
}
.year {
width: 15%;
display: flex;
justify-content: center;
align-items: center;
  button{
  width: 35px !important;
  height: 35px !important;
  display: flex;
  border-radius: 5px !important;
  justify-content: center;
  align-items: center;
  margin: 5px;
  }
    h5{
  border:#b4b4b4 2px solid;
  border-radius: 6px;
  background-color: whitesmoke;
  width: 40%;
  height: 36px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
    }
}
</style>
