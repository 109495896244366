<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      :title="`${$t('EmployeeTrackAndTrace')}`"
      right
      header-class="headerModalToggle"
      shadow
      no-close-on-esc
      width="50%"
      @close="onCancelToggle"
    >
      <template #title>
        <strong style="color: #101828;">{{ $t('EmployeeTrackAndTrace') }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px;"
          class="fa fa-close"
          @click="onCancelToggle"
        />
      </template>
      <div class="px-2 py-2">
        <div style="width: 100%;  ">
          <table
            ref="exportable_table"
            class="team_table"
          >
            <thead>
              <tr>
                <th>
                  {{ $t('OrderNumber') }}
                </th>
                <th>
                  {{ $t('ArticleName') }}
                </th>
                <th>
                  {{ $t('State') }}
                </th>
                <th>
                  {{ $t('Time') }}
                </th>
                <th>
                  {{ $t('ExecutorName') }}
                </th>
                <th>
                  {{ $t("ProcessedBy") }}
                </th>
                <th>
                  {{ $t('Message') }}
                </th>
              </tr>
            </thead>
            <tbody
              v-if="getTrackAndTraceByEmployee.length > 0"
            >

              <tr
                v-for="order in getTrackAndTraceByEmployee"
                :key="order.id"
              >
                <td>
                  {{ order.orderNumber }}
                </td>
                <td>
                  {{ order.articleNumber }}
                </td>
                <td>
                  {{ order.state }}
                </td>
                <td>
                  {{ convertUTCDateToLoacalDate(order.created) }}

                </td>
                <td>
                  {{ order.executorName }}
                </td>
                <td>
                  {{ order.processingUserName }}
                </td>
                <td>
                  {{ order.message }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td
                  colspan="5"
                  style="padding: 9px; text-align: left;"
                >
                  {{ $t('Userfeedback') }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';


export default {
  components: {

  },
  props: {
    users: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userObj: {},
      showOtherComponent: false,
      isactive: true,
      searchUser: '',
      noSearch: false,
      sortedR: false,
      sortedRI: false,
      selectedId: null,
      editUser: {
        userId: '',
        firstName: '',
        lastName: '',
        salutation: '',
        gender: '',
        city: '',
        dateOfBirth: '',
        phoneNumber: '',
        postalCode: '',
        doorNumber: '',
        street: '',
        country: '',
        email: '',
        role: '',
        resetPassword: false,
      },
      deactivateItemId: {},
      activateItemId: {},
      totalItems: '',
      page: 1,
      pageSize: 15,
      status: true,
      arrowCounter: 0,
      enterPressed: false,
      employeeId: null,
    };
  },
  computed: {
    ...mapGetters([
      'getEmployeeFeedbackPagination',
      'getInactiveUsers',
      'getFilteredActiveUsers',
      'getFilteredInactiveUsers',
      'getTotalItemsForUsers',
      'getTotalItemsForInActiveUsers',
      'getFeedback',
      'getLanguage',
      'getTrackAndTraceByEmployee',
    ]),
    filteredUsers() {
      // filter the users array to only show rows with the same id as the selected id
      return this.selectedId
        ? this.getEmployeeFeedbackPagination.filter((getEmployeeFeedbackPagination) => {
          return getEmployeeFeedbackPagination.userId === this.selectedId;
        })
        : this.getEmployeeFeedbackPagination;
    },
  },
  watch: {
    page(value) {
      if (this.isactive) {
        this.loadEmployeeFeedbackPagination({
          firstName: this.searchUser,
          pageNumber: value,
          pageSize: this.pageSize,
        });
      }
    },
    searchUser(value) {
      this.selectedId = null;
      if (value == '') {
        this.removeLists();
        if (this.isactive) {
          this.loadEmployeeFeedbackPagination({
            firstName: value,
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
        } else if (this.enterPressed) {
          // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.getInactiveUsersPagination({
            status: false,
            pageNumber: this.page,
            pageSize: this.pageSize,
            fullName: value,
          });
        }
      } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
        return;
      } else if (this.isactive) {
        this.filterActiveUsersByName(value);
      } else {
        this.filterInactiveUsersByName(value);
      }
      this.noSearch = true;
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions([
      'loadEmployeeFeedbackPagination',
      'getInactiveUsersPagination',
      'filterActiveUsersByName',
      'resetFilteredActiveUsers',
      'filterInactiveUsersByName',
      'resetFilteredInactiveUsers',
      'changeLoadingtoTrue',
      'loadVacationRequests',
      'assignEmployeeToStoree',
      'leaveRequest',
      'remainingDaysOfEmployee',
      'filterActiveUsersByName',
      'loadFeedback',
      'loadEmployeeFeedbackPagination',
    ]),
    onCancelToggle() {
      this.$emit('onCancelToggle');
    },
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false);

        await this.loadEmployeeFeedbackPagination({
          firstName: null,
          lastName: null,
          pageNumber: this.page,
          pageSize: this.pageSize,
        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false);
      }
    },
    matProps(users) {
      this.$emit('click', users);
      this.classNameCh = users.userId;
      this.$emit('show-the-component');
    },
    loadRemainingDays(value) {
      const currentYear = new Date().getFullYear();
      this.remainingDaysOfEmployee({ employeeUserId: value, year: currentYear });
    },
    async assignEmployeeToFiliale(obj) {
      await this.assignEmployeeToStoree({
        object: obj,
        successCallback: () => {
          this.loadEmployeeFeedbackPagination({
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
        },
      });
    },
    showSameId(id) {
      // set the selected id to the id of the clicked row
      this.selectedId = id;
    },
    loadFeedbackk(id) {
      // console.log('testt', id.userId)
      const currentYear = new Date().getFullYear();
      this.loadFeedback({ userId: id.userId, year: currentYear })
    },
    showAll() {
      // set the selected id to null to show all rows
      this.selectedId = null;
      this.$emit('show-other-component');
    },
    convertUTCDateToLoacalDate(date) {
      const local = moment.utc(date).local().format('DD-MMM-YYYY h:mm A')
      return local
    },

    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY');
    },
    onArrowDown(ev) {
      ev.preventDefault();
      if (this.arrowCounter < this.getFilteredActiveUsers.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault();
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling();
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') {
        return;
      }
      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true;
        this.searchByOn(this.getFilteredActiveUsers[this.arrowCounter]);
        this.removeLists();
        this.arrowCounter = 0;
        setTimeout(() => {
          this.enterPressed = false;
        }, 1000);
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      // console.log(liH);
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    sortByRole() {
      if (this.sortedR == false) {
        this.getEmployeeFeedbackPagination.sort((a, b) => {
          return a.role.localeCompare(b.role);
        });
        this.sortedR = true;
      } else {
        this.getEmployeeFeedbackPagination.sort((a, b) => {
          return a.role > b.role ? -1 : 1;
        });
        this.sortedR = false;
      }
    },
    sortByRoleI() {
      if (this.sortedRI == false) {
        this.getInactiveUsers.sort((a, b) => {
          return a.role.localeCompare(b.role);
        });
        this.sortedRI = true;
      } else {
        this.getInactiveUsers.sort((a, b) => {
          return a.role > b.role ? -1 : 1;
        });
        this.sortedRI = false;
      }
    },
    nameToShow(value) {
      if (value > 0) {
        return value
      }
      return '0'
    },
    async searchByOn(value) {
      const fullName = value.fullName;
      const spaceIndex = fullName.indexOf(' ');
      let firstName; let
        lastName;

      if (spaceIndex !== -1) {
        // Split the fullName into firstName and lastName
        firstName = fullName.slice(0, spaceIndex);
        lastName = fullName.slice(spaceIndex + 1);
      } else {
        // If there is no space, assume the entire name is the firstName
        firstName = fullName;
        lastName = ''; // You can set this to an empty string or handle it differently
      }

      this.searchUser = fullName;
      this.noSearch = false;
      await this.loadEmployeeFeedbackPagination({
        firstName,
        lastName,
        pageNumber: this.page,
        pageSize: this.pageSize,
      });
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredActiveUsers();
      this.resetFilteredInactiveUsers();
    },
  },
};
</script>

<style scoped>

.team_table td {
  padding: 16px 10px;
}

.b-sidebar.b-sidebar-right > .b-sidebar-header .close {
    margin-right: auto;
    border: 0 !important;
    background: white;
}
/* .button {
  width: 195px;
} */

/* .viewAllBtn{
  background: #FF274F;
}
.addEmployeeButton {
  width: 210px;
} */

@media screen and (max-width: 1600px) {
  .scroll {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1110px) {
  .button {
    margin-right: 10px;
    font-size: 12px;
    height: 36px;
    width: 150px;
  }
}

.dropdrop::-webkit-scrollbar {
  display: none;
}
span{
  color: black;
}
p{
  color: black;
}
table td{
  color: black;
}
.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;;
  z-index: 3;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.black {
  position: relative;
}

.black input {
  border: none;
  box-shadow: none;;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

@media screen and (max-width: 763px) {
  .con {
    flex-wrap: wrap;
  }

  .active-inactive-con {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .button {
    height: auto;
  }
}

.is-active {
  background-color: #dedede;
}
</style>
